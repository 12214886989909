import React, { useEffect } from 'react';
import axios from 'axios'

const Welcome = (props) => {

  const { isAuth, setInvokeAuth, setIsFormSubmitted, userMobileNumber, userSelectedLanguage, initiateAuthorization} = props;

  // const url = "https://api.urbanedge.xrvizion.com"

  // const sendLoginTime = async () => {
  //   try {
  //     console.warn("login time sent", userMobileNumber.current)

  //     const response = await axios.post(url + '/user/logintime', {
  //       mobileNumber: userMobileNumber.current,
  //     });
  //     console.log('Response:', response.data);
  //   }
  //   catch (error) {
  //     console.error('Error:', error);
  //   }
  // }

  // const invokeAuthFun = async () => {
  //   if (isAuth) {
  //     setIsFormSubmitted(true);
  //     console.log(userMobileNumber)
  //     const lsLangauge = localStorage.getItem("userSelectedLanguage")
  //     console.warn("lsLanguage", lsLangauge)
  //     userSelectedLanguage.current = lsLangauge || "EN"
  //     // await sendLoginTime()
  //   }
  //   else {
  //     setInvokeAuth(true);
  //   }
  // };

  // const updateLoadingScreenHeight = () => {
  //   const loadingScreen = document.querySelector('.loading-screen');
  //   if (loadingScreen) {
  //     loadingScreen.style.height = window.innerHeight + 'px';
  //   }
  // };

  // // Call the function on page load
  // useEffect(() => {
  //   updateLoadingScreenHeight();
  //   window.addEventListener('resize', updateLoadingScreenHeight);

  //   return () => {
  //     window.removeEventListener('resize', updateLoadingScreenHeight);
  //   };
  // }, []);


  return (
    <div className='loading-screen'>
      <div className="load-start-cont">
        {/* <div className='ff-m fs-xxl title-text'>Jain Housing</div> */}
        <div className='load-screen-logo'>
          <img src='https://xrv-xrc.s3.ap-south-1.amazonaws.com/NineReflex/Resources/REFLEX_PROJECT_Logo_min.png' alt='Reflex reality'></img> 
        </div>
        {/* <div className='note-cont'>
          <div className='ff-r fs-m note-cont-data'>
            We are committed to creating an “eco-friendly” living environment that meets global standards,
            thereby enhancing the quality of life for people nationwide. By adopting the latest technologies
            and adhering to the highest standards, we continuously strive to increase customer satisfaction
            through ongoing improvements to our products and services.
          </div>
        </div> */}
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
          <div className='ff-m subtitle-text-landing' style={{ color: "#fff", opacity: "0.9" }}>Virtual AI tour of Nine Reflex</div>
          <button className='start-button ff-sb fs-sm' style={{ marginTop: "1.25rem" }} onClick={initiateAuthorization}>Enter</button>
        </div>
      </div>
      <div className="xrv-logo">
        <div className='xrv-logo-text ff-l fs-xs'>powered by</div>
        <img className="xrv-logo-img" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/xrv.png" alt=""></img>
      </div>
    </div>
  );
};

export default Welcome;